import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, inject } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { LayoutSidebarService, LogService } from "@shared/services";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements AfterContentChecked{
  protected logService = inject(LogService);
  protected layoutSidebarService = inject(LayoutSidebarService);
  protected cdr = inject(ChangeDetectorRef);

  @ViewChild("drawer")
  set setDrawer(drawer: MatDrawer) {
    this.layoutSidebarService.drawer = drawer;
  }

  constructor() {
    this.logService.mixpanelInit();
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }
}
