import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-oauth-callback',
  templateUrl: './oauth-callback.component.html',
  styles: ``
})
export class OAuthCallbackComponent implements OnInit {
  errorMessage: string | null = null;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.errorMessage = this.route.snapshot.queryParamMap.get('message');

    if (!window.opener) {
      return
    }

    if (this.errorMessage) {
      window.opener.postMessage({ type: 'oauth-callback', status: 'error', message: this.errorMessage }, '*')
    } else {
      window.opener.postMessage({ type: 'oauth-callback', status: 'success' }, '*')
    }

    setTimeout(() => {
      window.close()
    }, 1000)
  }
}
